:root {
    /* Set size of title font and set offset (to overlap sections) by 0.6 of the font size */
    --titleSize: 8rem;
    --titleOffset: calc(0.6 * var(--titleSize));
}

.feedbackSection {
    width: 100%;
    background-image: linear-gradient(to left, #bbf7b6, #b6f7b0, #b1f8ab, #acf8a5, #a7f89f);
    margin-top: 9rem;
    padding-bottom: 0rem;
}

.feedbackContent {
    /* Offset by --headingOffset so heading overlaps sections */
    transform: translate(0, calc(-1*var(--titleOffset))); 
    -webkit-transform: translate(0, calc(-1*var(--titleOffset))); 
    -moz-transform: translate(0, calc(-1*var(--titleOffset)));  
    -ms-transform: translate(0, calc(-1*var(--titleOffset)));  
}

.feedbackTitle {
    /* Just helps to visually balance 'Oh...' and following text elements */
    margin-left: -0.02em;
    margin-bottom: 0.2rem;
}

.feedbackText {
    margin-top: 3rem;
}

.feedbackText button {
    margin-top: 3rem;
}

@media screen  and (max-width: 600px){ 
    .feedbackTitle {
        /* Switch heading font variable */
        --titleSize: 6.5rem;
        text-align: left;
    }
}
