.arrowBackground {
    position: relative;
    width: var(--arrow-width);
    height: var(--arrow-width);
    background-image: linear-gradient(-135deg, #5b5b5b 4.55%, #000000 4.55%, #000000 50%, #5b5b5b 50%, #5b5b5b 54.55%, #000000 54.55%, #000000 100%);
    background-size: 31.11px 31.11px;
    overflow: hidden;
}

.squareLeft, .squareRight {
    position: absolute;
    top: 0;
    width: calc(var(--arrow-width) / 3);
    height: calc(var(--arrow-width) / 3);
    background-color: white;
}

.squareRight {
    right: 0;
}

.triangleLeft, .triangleRight {
    position: absolute;
    bottom: 0px;
    border-left: 0px solid transparent;
    border-right: calc(0.5*var(--arrow-width) + 2px) solid transparent;
    border-bottom: calc(var(--arrow-width) / 3 * 2 + 2px) solid white;
}

.triangleRight {
    right: 0;
    border-left: calc(0.5*var(--arrow-width)) solid transparent;
    border-right: 0px solid transparent;
}