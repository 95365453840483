.lightbulbContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background-image: linear-gradient(45deg, #242424 4.55%, #000000 4.55%, #000000 50%, #242424 50%, #242424 54.55%, #000000 54.55%, #000000 100%);
    background-size: 31.11px 31.11px;
}

.lightbulb, .lightswitch {
    position: absolute;
    right: 8rem;
    top: 0rem;
    z-index: 1;
}

.lightbulb {
    width: 4.5rem;
    pointer-events: none;
    transform-origin: top;
    animation: swing 3s infinite ease-in-out;
    -webkit-animation: swing 3s infinite ease-in-out;
    -moz-animation: swing 3s infinite ease-in-out;
    -ms-animation: swing 3s infinite ease-in-out;
}

@keyframes swing {
	0% {
		transform: rotate(-0.4deg);
        -webkit-transform: rotate(-0.4deg);
        -moz-transform: rotate(-0.4deg);
        -ms-transform: rotate(-0.4deg);
	}

	50% {
		transform: rotate(0.4deg);
        -webkit-transform: rotate(0.4deg);
        -moz-transform: rotate(0.4deg);
        -ms-transform: rotate(0.4deg);
	}

	100% {
		transform: rotate(-0.4deg);
        -webkit-transform: rotate(-0.4deg);
        -moz-transform: rotate(-0.4deg);
        -ms-transform: rotate(-0.4deg);
	}
}

.lightswitch {
    top: 12rem;
    width: 4.6rem;
    right: 15rem;
    cursor: pointer;
    padding: 1rem;
}

.lightswitch:focus-visible {
    outline: 1px dashed white;
}

.preloadLightbulb {
    width: 0px;
    height: 0px;
    opacity: 0;
}

/* Adjust lightbulb position for difference viewport widths */
@media screen and (max-width: 1000px) {
    .lightbulb {
        right: calc(2rem + var(--main-margin));
    }
    .lightswitch {
        right: calc(var(--main-margin) + 9rem);
    }
}

@media screen and (max-width: 820px) {
    .lightbulb {
        right: var(--main-margin);
    }
    .lightswitch {
        right: calc(var(--main-margin) + 7rem);
    } 
}

@media screen and (max-width: 650px) {
    .lightbulb, .lightswitch {
        margin-top: -50px;
    }
    .lightswitch {
        right: calc(var(--main-margin) + 6rem);
    } 
}