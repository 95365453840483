.outroContainer {
    /* Shift up to make gap between arrow and grey box 3rem (same as others Story.js iterations) */
    margin-top: -2rem;
}

/* Hides box shadow above .outroTop */
.outroShadowHide {
    position: relative;
    height: 25px;
    background-color: white;
    z-index: 1;
}

/* PEOPLE EXPERIENCES QUESTIONS SECTION */
.outroTop {
    position: relative;
    background-color: #f6f5f5;
    padding-block: 4rem;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
}

/* THIS IS WHEN YOU KEEP TALKING SECTION */
.outroBottom {
    background: 
        linear-gradient(45deg, #ffb7ee 34%, transparent 25%) 0px -8px,
        linear-gradient(-45deg, #ffb7ee 34%, transparent 25%) 0px -8px,
        linear-gradient(135deg, #ffb7ee 34%, transparent 25%) -35px -8px,
        linear-gradient(-135deg, #ffb7ee 34%, transparent 25%) -35px -8px;
    background-size: 70px 50px;
    background-color: #f8dbf1;
    padding-block: 4rem;
}

.outroBottomContent {
    display: flex;
    flex: 1fr auto;
    gap: 6rem;
    justify-content: space-between;
}

.outroTalking h4 {
    width: max-content;
    margin-bottom: 0;
    margin-right: -0.3em;
}

.outroConversations {
    display: flex;
    align-items: center;
    padding: 0.2em 1em;
    background-color: white;
    text-align: left;
}

.outroArrowDot {
    /* Offsets dots */
    margin-top: 2rem;
}

.downArrow {
    /* Add additional margins to arrow */
    margin-top: 1rem;
    margin-bottom: 2rem;
    width: 6rem;
    opacity: 1;
    pointer-events: none;
    animation: pulse 1s infinite ease-in-out;
    -webkit-animation: pulse 1s infinite ease-in-out;
    -moz-animation: pulse 1s infinite ease-in-out;
    -ms-animation: pulse 1s infinite ease-in-out;
}

@keyframes pulse {
	0% {
		transform: translate(0, -0.12rem);
        -webkit-transform: translate(0, -0.12rem);
        -moz-transform: translate(0, -0.12rem);
        -ms-transform: translate(0, -0.12rem);
	}

	50% {
		transform: translate(0, 0.12rem);
        -webkit-transform: translate(0, 0.12rem);
        -moz-transform: translate(0, 0.12rem);
        -ms-transform: translate(0, 0.12rem);
	}

	100% {
		transform: translate(0, -0.12rem);
        -webkit-transform: translate(0, -0.12rem);
        -moz-transform: translate(0, -0.12rem);
        -ms-transform: translate(0, -0.12rem);
	}
}

/* PLACEMENT OF DOT CONTAINER ONLY (see Dots.module.css) */
.outroDots {
    position: absolute;
    top: 0;
}

@media screen and (max-width: 800px){
    .outroBottomContent {
        gap: 4rem;
    }
}

@media screen and (max-width: 767px){
    .outroBottomContent { 
       flex-direction: column;
    }
}

@media screen and (max-width: 500px){
    .outroTalking h4 {
        width: auto;
    }
}
