/* LEAH DYLAN GRACE SECTION */
.introSectTop {
    /* Position relative needed to enable z-index */
    position: relative;
    background: 
        linear-gradient(45deg, #ffb7ee 34%, transparent 25%) 0px -8px,
        linear-gradient(-45deg, #ffb7ee 34%, transparent 25%) 0px -8px,
        linear-gradient(135deg, #ffb7ee 34%, transparent 25%) -35px -8px,
        linear-gradient(-135deg, #ffb7ee 34%, transparent 25%) -35px -8px;
    background-size: 70px 50px;
    background-color: #f8dbf1;
    padding-top: 4rem;
    padding-bottom: 8.5rem;
    /* Place section underneath introSectBottom::before (and its shadow) */
    z-index: -2;
}

.introNames {
    width: calc(100vw - 3rem);
    /* Adjust to fit para length */
    max-width: 32rem;
    display: flex;
    justify-content: space-between;
}

.violence {
    margin-top: 1em;
    font-weight: 500;
}

/* FLOATING 'RELATE' SECTION - HELD IN BOTTOM SECT */
.introSectCenter {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: -10.5rem;
    color: white;
    text-align: center;
}

.introSectCenterText {
    background-color: white;
    box-shadow: 0px 0 5px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 0 5px rgba(0, 0, 0, 0.1);
    color: black;
    padding: 0.5rem 1.5rem;
}

/* WATCH TALK LISTEN SECTION */
.introSectBottom {
    position: relative;
    background-color: #f6f5f5;
    padding-top: 6.5rem;
    padding-bottom: 4rem;
}

/* Box shadow */
.introSectBottom::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 25px;
    box-shadow: 0px 0 25px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 0 25px rgba(0, 0, 0, 0.1);
    /* Place psuedo-element beneath introSectBottom */
    z-index: -1;
  }

/* Two versions of 'Watch, Talk, Listen' section (see Intro.js)  */
.introWatchTalkListen, .introWatchTalkListenMobile {
    text-align: right;
}

.introWatchTalkListen span {
    margin-left: 0.15em;
}

.introWatchTalkListenMobile {
    display: none;
}

/* --- MEDIA QUERIES --- */

@media screen and (max-width: 850px) {
    .violence {
        max-width: 500px;
    }
}

@media screen  and (max-width: 600px){
    /* Various changes to stack Leah, Dylan, Grace, and balance layout */
    .introNames {
        width: 100%;
        padding-left: 0rem;
        flex-direction: column;
    }
    .introNames h5 {
        line-height: 1.6em;
    }
    .introSectCenter {
        margin-top: -13rem;
    }
    .introSectCenterText {
        max-width: 300px;
    }
    .introSectBottom {
        padding-top: 8.5rem;
    }
    .introWatchTalkListen {
        display: none;
    }
    .introWatchTalkListenMobile {
        width: 100%;
        /* Use flexbox to push smaller p container to the right */
        display: flex;
        justify-content: right;
    }
}

@media screen and (max-width: 500px){
     .introSectCenterText {
        max-width: calc(100% - 2*var(--main-margin));
     }
}

@media screen  and (max-width: 320px){
    /* To make sure '[...] time out' doesn't break over two lines */
    .introWatchTalkListenMobile p {
        min-width: 300px;
    } 
}