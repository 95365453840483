.resourcesSection {
    width: 100%;
    margin-top: 9rem;
    padding-bottom: 1.5rem;
    /* Set font size to offset .creditsTitle accurately  */
    font-size: 6rem;
    background-color: #F6F5F5;
}

.resourcesContent {
    transform: translate(0, -0.5em);
}

.resourcesTitle {
    /* Position relative and line height 1em used to preserve height of title when <Typewriter> is hidden - see credits.js */
    position: relative;
    line-height: 1em;
    font-size: 6rem;
    /* Just helps to visually balance */
    margin-left: -0.03em;
    margin-bottom: 0.2rem;
    min-height: 1em;
}

.resourcesLinks {
    margin-top: 4rem;
    max-width: 750px;
}

.resourcesLinks p {
    margin-bottom: 2.5rem;
}

.resourcesLinks p:last-child {
    margin-bottom: 0;
}

.resourcesLinks a {
    background-image: linear-gradient(to right, rgb(40,40,40) 50%, transparent 50%);
    background-size: 210% 100%;
    background-position: right bottom;
    padding: 0.4rem 0.6rem;
    border: 2px solid rgb(40,40,40);
    color: black;
    text-decoration: none;
    margin-right: 0.1em;
    transition: all 300ms ease;
}

.resourcesLinks a:focus-visible {
    background-position: left bottom;
    color: white; 
    outline: none;
}

@media (hover) {
    .resourcesLinks a:hover {
        background-position: left bottom;
        color: white; 
        outline: none; 
    }
}

@media screen  and (max-width: 600px){ 
    .resourcesSection {
        margin-top: 8rem;
    }
    .resourcesTitle {
        font-size: 5.5rem;
        text-align: left;
    }
    .resourcesSection {
        font-size: 5rem;
    }
}

@media screen  and (max-width: 524px){ 
    .resourcesSection {
        margin-top: 7.5rem;
    }
    .resourcesTitle {
        /* Switch to font size based on viewport width */
        font-size: 17vw;
    }
    .resourcesSection {
        font-size: 17vw;
    }
}