.entrySection {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 1;
    overflow-x: hidden;
}

/* Diagonal background that animates out when entry screen closes */
.entryBackground {
    position: fixed;
    bottom: 0;
    width: 0; 
    height: 0; 
    border-bottom: 200vh solid white; 
    border-right: 200vw solid transparent; 
}

.backgroundPattern {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: 
        linear-gradient(45deg, white 34%, transparent 25%) 0px -8px,
        linear-gradient(-45deg, white 34%, transparent 25%) 0px -8px,
        linear-gradient(135deg, white 34%, transparent 25%) -35px -8px,
        linear-gradient(-135deg, white 34%, transparent 25%) -35px -8px;
    background-size: 70px 50px;
    background-color: rgb(247,247,247);
}

.entryContent {
    /* Reduce from 1000px in .mainContainer (App.css) to balance on page a bit nicer */
    max-width: 740px;
    display: flex;
    flex-direction: column;
    /* background-color: white; */
    padding-top: 4rem;
    padding-bottom: 1rem;
    overflow-y: scroll;
    scrollbar-width: 0;
}

.entryContent::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}

.entryContent .logo {
    width: 260px;
    margin-bottom: 3.5rem;
}

.entryContent h4 {
    padding-bottom: 0.5rem;
}

.entryContent h3 {
    font-size: 2rem;
    padding-top: 1.5em;
    padding-bottom: 0.6em;
}

.highlightColor {
    background-color: #bbf7b6;
}

/* Button styled in app.css */
/* Here add black border too */
.entryContent button {
    margin-block: 3.5rem;
    border: 2px solid rgb(40,40,40);
}

@media screen and (max-width: 600px) {
    .entryContent {
        padding-top: 4rem;
    }
}