/* GREEN HEADER */
.helloHeader {
    width: 100%;
    height: 200px;
    background-color: #BBF7B6;
    background-image: linear-gradient(to left, #bbf7b6, #b6f7b0, #b1f8ab, #acf8a5, #a7f89f);
}

/* ALL CONTENT IN WHITE BOX */
.helloContent {
    position: relative;
    margin-bottom: 3rem;
}

.greeting {
    /* Adjust to align green header and bottom of horizontal elements of 'HELLO' */
    margin-top: -0.6em;
}

.greetingHey, .greetingHi {
    display: none;
}

/* THIS IS WHEN TAGLINE (BOX) AND PARAGRAPH */
.helloIntro {
    max-width: 700px;
    margin-top: 4rem;
}

.helloIntro p {
    margin-bottom: 1.5em;
}

/* PLACEMENT OF ARROW */
.downArrow {
    width: 6rem;
    margin-top: 3.5rem;
    opacity: 1;
    pointer-events: none;
    animation: pulse 1s infinite ease-in-out;
    -webkit-animation: pulse 1s infinite ease-in-out;
    -moz-animation: pulse 1s infinite ease-in-out;
}

@keyframes pulse {
	0% {
		transform: translate(0, -0.12rem);
        -webkit-transform: translate(0, -0.12rem);
        -moz-transform: translate(0, -0.12rem);
        -ms-transform: translate(0, -0.12rem);
	}

	50% {
		transform: translate(0, 0.12rem);
        -webkit-transform: translate(0, 0.12rem);
        -moz-transform: translate(0, 0.12rem);
        -ms-transform: translate(0, 0.12rem);
	}

	100% {
		transform: translate(0, -0.12rem);
        -webkit-transform: translate(0, -0.12rem);
        -moz-transform: translate(0, -0.12rem);
        -ms-transform: translate(0, -0.12rem);
	}
}

/* PLACEMENT OF DOT CONTAINER ONLY (see Dots.module.css) */
.helloDots {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    overflow-x: hidden;
}

/* --- MEDIA QUERIES --- */

@media screen  and (max-width: 767px){
    /* Switch to 'Hey' for tablet */
    .greetingHello {
        display: none;
    }
    .greetingHey{
        display: block;
    }
}

@media screen  and (max-width: 650px){
    /* Reduce green header height and adjust lightbulb position*/
    .helloHeader {
        height: 150px;
    }
    .helloIntro h4 {
        max-width: 400px;
    }
     /* Switch to 'Hi' for tablet */
    .greetingHey{
        display: none;
    }
    .greetingHi {
        display: block;
    }
}