.pauseContainer {
    margin: 0 auto;
    padding: 4rem 1.5rem;
    background-image: linear-gradient(135deg, #dedcdc 4.55%, #f6f5f5 4.55%, #f6f5f5 50%, #dedcdc 50%, #dedcdc 54.55%, #f6f5f5 54.55%, #f6f5f5 100%);
    background-size: 31.11px 31.11px;
    color: black;
}

@media screen and (max-width: 480px) {
    .pauseContainer {
        padding: 3rem 1.5rem;
        display: flex;
        justify-content: center;
    }
}