.creditsSection {
    width: 100%;
    margin-top: 4rem;
    padding-bottom: 2rem;
    /* Set font size to offset .creditsTitle accurately  */
    font-size: 6rem;
    background:
    radial-gradient(circle at 100% 50%, transparent 30%, rgba(255,255,255,0.25) 31%,  rgba(255,255,255,0.25) 33%, transparent 34%, transparent),
    radial-gradient(circle at 0% 50%, transparent 30%, rgba(255,255,255,0.25) 31%,  rgba(255,255,255,0.25) 33%, transparent 34%, transparent) 0 -50px;
    background-color: #96BBE0;
    background-size: 59px 100px;
}

.creditsContent {
    transform: translate(0, -0.5em);
}

.creditsTitle {
    /* Position relative and line height 1em used to preserve height of title when <Typewriter> is hidden - see credits.js */
    position: relative;
    line-height: 1em;
    font-size: 6rem;
    /* Just helps to visually balance */
    margin-left: -0.03em;
    margin-bottom: 0.2rem;
    min-height: 1em;
}

/* THREE BOXES FOR PERFORMERS */
.creditsActors {
    display: flex;
    justify-content: space-between;
    margin-top: 4rem;
}

.actorsItem {
    background-color: white;
    padding: 0.9rem 1.4rem;
    width: 257px;
    display: flex;
    flex-direction: column;
}

.actorsFilmTitle {
    font-size: 1.3rem;
    margin-bottom: 1rem;
}

/* CREATIVE TEAM DROPDOWN */
.creativeTeam {
    background-color: white;
    margin-top: 2.5rem;
    padding: 0.9rem 1.4rem;
    width: 100%;
}

.creativeTeamHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.creativeTeamTitle {
    font-size: 1.3rem;
}

.creativeTeamSection {
    margin-top: 1.6rem;
}

/* Style down icon to open credits */
.downArrowIcon {
    height: 2rem;
    margin-top: -0.1rem;
    cursor: pointer;
    animation: pulse 1s infinite ease-in-out;
    -webkit-animation: pulse 1s infinite ease-in-out;
    -moz-animation: pulse 1s infinite ease-in-out;
    /* Helps push outline out when focused */
    padding: 0.5rem;
    /* Account for padding (for focus) pushing element from edge of container */
    margin-right: -0.5rem;
}

@keyframes pulse {
	0% {
		transform: translate(0, -0.08rem);
        -webkit-transform: translate(0, -0.08rem);
        -moz-transform: translate(0, -0.08rem);
        -ms-transform: translate(0, -0.08rem);
	}

	50% {
		transform: translate(0, 0.08rem);
        -webkit-transform: translate(0, 0.08rem);
        -moz-transform: translate(0, 0.08rem);
        -ms-transform: translate(0, 0.08rem);
	}

	100% {
		transform: translate(0, -0.08rem);
        -webkit-transform: translate(0, -0.08rem);
        -moz-transform: translate(0, -0.08rem);
        -ms-transform: translate(0, -0.08rem);
	}
}

.rotateIcon {
    transform: rotate(180deg);
    animation: none;
    -webkit-animation: none;
    -moz-animation: none;
}

/* LOGOS */

.logos {
    display: flex;
    align-items: flex-end;
    /* Gap is 4% of container width. 2% removed from each image in .logos img */
    gap: 4%;
    padding-top: 2rem;
    padding-bottom: 3rem;
}

.logos img {
    width: 48%;
}

@media screen  and (max-width: 935px){ 
    /* Column performers boxes but put items next to one another */
    .creditsActors {
        flex-direction: column;
        gap: 2rem;
    }
    .actorsItem {
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
    }
    .actorsFilmTitle, .creativeTeamTitle {
        width: 220px;
        margin-bottom: 0;
        line-height: auto;
    }
    .actors {
        display: flex;
        gap: 2rem;
    }
    .creativeTeam {
        margin-top: 2rem;
    }
    .logos {
        flex-direction: column;
        align-items: center;
        gap: 2.5rem;
    
    }
    .logos img {
        width: 65%;
        min-width: 450px;
    }
}

@media screen  and (max-width: 800px){ 
    /* Stack the names of the actors */
    .actorsItem {
        flex-direction: column;
    }
}

@media screen  and (max-width: 600px){ 
    .creditsTitle {
        font-size: 5.5rem;
        text-align: left;
    }
    .creditsSection {
        font-size: 5rem;
    }
    .logos img {
        width: 85%;
        min-width: 350px;
    }
}

@media screen  and (max-width: 524px){ 
    .creditsTitle {
        /* Switch to font size based on viewport width */
        font-size: 17vw;
    }
    .creditsSection {
        font-size: 17vw;
    }
    /* Stack the film title as well as the name of actors */
    .actors {
        flex-direction: column;
        gap: 0;
    }
    .logos img {
        width: 100%;
        min-width: 0px;
    }
}