@import url('https://fonts.googleapis.com/css2?family=Anonymous+Pro&family=Inter+Tight:wght@300;400;500&family=Quicksand:wght@400;500;600&family=Roboto:wght@100;300;400;500;700&display=swap');

:root {
    --main-margin: 3rem;
    /* Used to set timeline margin-left offset too */
    --arrow-width: 5rem;
}

@media screen and (max-width: 600px){
    :root {
        --main-margin: 2rem;
        --arrow-width: 4rem;
    }
}

@media screen and (max-width: 480px){
    :root {
        --main-margin: 1.8rem;
    }
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    /* Stop grey flash behind element when interacting on mobile */
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

*:focus-visible {
    outline-color: #00ADEF;
}

/*------- FONTS ------- */

/* HELLO FONT */
h1, h2 {
    font-family: 'Inter Tight', sans-serif;
    font-size: 10rem;
    letter-spacing: 0.08em;
    font-weight: 500;
    text-transform: uppercase; 
    margin-left: -0.06em;
    background-image: linear-gradient(135deg, #5b5b5b 4.55%, #000000 4.55%, #000000 50%, #5b5b5b 50%, #5b5b5b 54.55%, #000000 54.55%, #000000 100%);
    background-size: 31.11px 31.11px;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}

/* FILM - NAME BIG FONT */
h2 {
    font-size: 8rem;
    /* Margins to adjust for line height and font spaces */
    margin-right: -0.128em;
    margin-bottom: -0.224em;
}

/* THANKS FOR STOPPING BY FONT */
h3 {
    font-family: 'Quicksand', sans-serif;
    font-size: 2.18rem;
    font-weight: 100;
    line-height: 1.7em;
    letter-spacing: 0.070em;
}

@media screen and (max-width: 767px){
    h3 {
        font-size: 2rem;
    }
}

@media screen and (max-width: 400px){
    h2 {
        font-size: 6rem;
    }
    h3 {
        font-size: 1.8rem;
    }
}

/* THIS IS WHEN BOX FONT */
h4 {
    font-family: 'Inter Tight', sans-serif;
    font-size: 2.4rem;
    font-weight: 300;
    letter-spacing: 0.3em;
    line-height: 2em;
    margin-bottom: 0.7em;
    text-transform: uppercase;
}

/* REDUCES DOT SPACING FOR THIS IS WHEN... */
.thinTextSpacing {
    letter-spacing: 0.15em;
}

/* NAMES AND ACCENTS (SIZE VARIES) */
h5, .paraAccent {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 2.7rem;
    text-transform: uppercase;
    margin-left: -0.06em;
}

.paraAccent {
    font-size: 1.5rem;
    font-weight: 500;
}

.filmIntroText {
    display: flex;
    justify-content: center;
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    font-size: 1.5rem;
    line-height: 2em;
    font-weight: 400;
    letter-spacing: 0.05em;
}

p {
    font-family: 'Quicksand', sans-serif;
    font-size: 1.2rem; 
    line-height: 2.2em;
}

.boldParagraph {
    font-weight: 600;
}

/* LARGER PARA FONT FOR EMPHASIS */
.biggerParagraph {
    font-size: 1.35rem; 
    line-height: 2.0em;
}

.highlightParagraph {
    background-color: #ededed;
    padding: 0.2em 0.3em;
}

.messageFont {
    font-family: 'Anonymous Pro', monospace;
}

/* BUTTON */
button {
    width: fit-content;
    padding: 0.5em 2.2em;
    background-image: linear-gradient(to right, rgb(40,40,40) 50%, white 50%);
    background-size: 210% 90%;
    background-position: right bottom;
    transition: all 300ms ease;
    color: black;
    cursor: pointer;
    border: none;
    /* Stop button highlight on tap for mobile */
    -webkit-tap-highlight-color: transparent;
}

button:focus-visible {
    background-position: left bottom;
    color: white; 
    outline: none;
}

@media (hover) {
    button:hover {
        background-position: left bottom;
        color: white; 
        outline: none; 
    }
}

@media screen and (max-width: 500px){
    button {
        width: 100%;
    }
}


/* MAIN CONTAINER  */
.mainContainer {
    position: relative;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding-inline: var(--main-margin);
}

.main-wrapper {
    width: 100%;
    scrollbar-width: 0;
}

.main-wrapper::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}



/* 
CSS Stripe generator - https://stripesgenerator.com/ 
Vendor support and prefix requirements - https://caniuse.com/ 
*/