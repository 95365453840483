/* DOTS */
.dotContainer {
    width: 1000px;
    /* Counter main margin so dots can push up towards edge of screen when needed */
    margin-left: calc(-1*var(--main-margin));
}

.dots {
    float: right;
    display: flex;
    gap: 3rem;
    /* Push 1/2 dot width right so its cut in half over the edge of the screen (at 1000px and less) */
}

.dot {
    width: 2rem;
    height: 2rem;
    border: 1px solid black;
    border-radius: 50%;
    opacity: 0.2;
}

@media screen and (max-width: 1000px) {
    .dotContainer {
        width: 100%;
    }
}

@media screen  and (max-width: 767px){
    /* Move 1 dot to the right: -1rem (initial offset) -2rem (dot size) - 3rem (gap between dots) */
    .dots {
        margin-right: -5rem;
    }
}

@media screen  and (max-width: 600px){
    /* Move 2 dots to the right: -1rem (initial offset) -4rem (2*dot size) - 6rem (2*gap between dots) */
    .dots {
        margin-right: -9rem;
    }
}