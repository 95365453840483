:root {
    --timeline-width: 2px;
    /* Circle at the top and bottom of timeline */
    /* Top is in Story.js, Bottom is in Dialogue.js */
    --timeline-node: 0.9rem;
}

.storyContainer {
    position: relative;
    margin-top: 3rem;
    margin-bottom: 5rem;
}

/* PLACEMENT OF ARROW ONLY (SEE APP.CSS) */
.downArrow {
    margin-left: calc(-0.5*var(--timeline-width));
    animation: pulse 1s infinite ease-in-out;
    -webkit-animation: pulse 1s infinite ease-in-out;
    -moz-animation: pulse 1s infinite ease-in-out;
    -ms-animation: pulse 1s infinite ease-in-out;
}

.lastDownArrow {
    margin-top: 2rem;
    margin-left: 0;
    animation: pulse 1s infinite ease-in-out;
    -webkit-animation: pulse 1s infinite ease-in-out;
    -moz-animation: pulse 1s infinite ease-in-out;
    -ms-animation: pulse 1s infinite ease-in-out;
}

@keyframes pulse {
	0% {
		transform: translate(0, -0.1rem);
        -webkit-transform: translate(0, -0.1rem);
        -moz-transform: translate(0, -0.1rem);
        -ms-transform: translate(0, -0.1rem);
	}

	50% {
		transform: translate(0, 0.1rem);
        -webkit-transform: translate(0, 0.1rem);
        -moz-transform: translate(0, 0.1rem);
        -ms-transform: translate(0, 0.1rem);
	}

	100% {
		transform: translate(0, -0.1rem);
        -webkit-transform: translate(0, -0.1rem);
        -moz-transform: translate(0, -0.1rem);
        -ms-transform: translate(0, -0.1rem);
	}
}

/* PLACEMENT OF DOT CONTAINER ONLY (see Dots.module.css) */
.filmDots {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    overflow-x: hidden;
}

@media screen  and (max-width: 600px){
    .downArrow, .lastDownArrow {
        /* Move arrow to the left to make more room for timeline */
        margin-left: -1rem;
    }
}