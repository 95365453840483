:root {
    --film-width: 80%;
    /* Padding top for responsive video (to provide correct height) */
    /* Aspect ratio - 80% / (2048/1080) */
    --padding-for-film-width: 42.1875%;
}

/* GENERAL CONTAINER FOR FILM ELEMENTS */
.filmSection {
    margin-top: 10rem;
}

.filmBlackout {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.8);
    z-index: 2;
}

/* HOLDS FILM AND ELEMENTS - SET HEIGHT */
.filmContainer {
    position: relative;
    /* Allows center positioning of intro etc */
    padding-top: var(--padding-for-film-width);
}

/* Adjust styling for Dylan's film as aspect ratio is different (see Film.js) */
.dylan {
    --padding-for-film-width: 45%;
}

/* TIMELINE ELEMENT NEXT TO FILM (REST IS IN DIALOGUE.JS) */
.filmTimeline {
    position: absolute;
    top: -2rem;
    left: calc(0.5*var(--arrow-width) - 0.5*var(--timeline-width));
    width: var(--timeline-width);
    /* Make taller than the film container to cover gap between itself and the next timeline (in Dialogue.js) */
    height: calc(100% + 13rem);
    background-color: black;
    margin-top: -5rem;
    /* Push behind any following elements without absolute position*/
    z-index: -1;
}

/* Circle element before timeline */
.filmTimeline::before {
    position: absolute;
    top: 0;
    left: calc(-0.5*var(--timeline-node) + 0.5*var(--timeline-width));
    content: "";
    width:  var(--timeline-node);
    height: var(--timeline-node);
    background-color: black;
    border-radius: 50%;
}

/* REACT PLAYER */
.playerWrapper {
    position: absolute;
    top: 0px;
    right: 0px;
    width: var(--film-width);
    padding-top: var(--padding-for-film-width);
    z-index: 3;
}
  
.reactPlayer {
    position: absolute;
    top: 0;
}

/* Loading gif */
.filmLoadingContainer {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F5F4F6;
}

.filmLoadingContainer img {
    width: 150px;
    opacity: 0.2;
}

/* TEXT ELEMENTS AROUND VIDEO */

/* Non-mobile version of the film intro (to the left of the film) */
.filmIntro {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -webkit-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    left: -2rem;
    background-image: linear-gradient(to right, #fff68d, #fef692, #fdf597, #fcf59c, #fbf4a1);
    box-shadow: 5px 5px 10px -6px rgba(0,0,0,0.3);
    -webkit-box-shadow: 5px 5px 10px -6px rgba(0,0,0,0.3); 
    color: black;
    padding: 1.5rem 4rem;
    z-index: 4;
}

/* Mobile version of the film intro (underneath film)*/
.filmIntroMobile {
    display: none;
    width: 100%;
    background-image: linear-gradient(to right, #fff68d, #fef692, #fdf597, #fcf59c, #fbf4a1);
    box-shadow: 5px 5px 10px -6px rgba(0,0,0,0.3);
    -webkit-box-shadow: 5px 5px 10px -6px rgba(0,0,0,0.3); 
}

.filmIntroText {
    /* Break text over lines using ch max-width */
    max-width: 10ch;
    margin: 0;
}

.filmName {
    position: absolute;
    top: -1.1em;
    right: 0em;
}

/* --- MEDIA QUERIES --- */

/* Nudge film intro and title closer to the film */
@media screen and (max-width: 1100px){
    .filmIntro {
        left: -1rem;
    }
    .filmIntroText {
        max-width: 10ch;
    }
}

@media screen and (max-width: 900px) {
    /* Make film intro a bit smaller to prevent being too close to Vimeo controls */
    .filmIntro {
        padding: 1.1rem 3.5rem;
    }
    .filmName {
        font-size: 7rem;
    }
    .filmIntroText {
        font-size: 1.3rem;
    }
}

@media screen  and (max-width: 767px){ 
    /* Change film dimensions fill viewport*/
    :root {
        --film-width: calc(100% + 2*var(--main-margin));
        --padding-for-film-width: 52.73vw;
    }
    /* Adjust styling for Dylan's film as aspect ratio is different (see Film.js) */
    .dylan {
        --padding-for-film-width: 56.25vw;
    }
    /* Hide non-mobile film intro and show mobile version */
    .filmIntro {
        display: none;
    }
    .filmIntroMobile {
        display: block;
        /* Adjust sizing for full width of film */
        width: var(--film-width);
        margin-left: calc(-1*var(--main-margin)); 
    }
    .filmIntroText {
        max-width: 100%;
        margin-top: 1em;
        line-height: 1.5em;
        padding: 0.7em 0.5em;
        text-align: center;
    }
    .playerWrapper {
        /* Account for main margin to have film align with left of viewport */
        left: calc(-1*var(--main-margin));
    } 
}

@media screen  and (max-width: 600px){ 
    /* Move timeline 1rem left to make more room for dialogue */
    .filmTimeline {
        left: calc(0.5 * var(--arrow-width) - 1rem);
    }
    .filmName {
        /* Switch to font size based on viewport width */
        font-size: 19vw;
    }
}