:root {
    --dialogue-row-gap: 2.5rem;
    --checkbox-width: 2.5rem;
}

/* MAIN CONTAINERS */
.dialogueSection {
    position: relative;
    margin-left: 0rem;
}

.dialogueContent {
    /* Locks content to the left of the video (for down to 768px) */
    padding-top: 5rem;
    padding-bottom: 8rem;
    /* Increase margin-right to balance text content more towards the centre of the page */
    margin-right: var(--main-margin);
}

/* Less padding on final dialogue (grace), so shorter timeline, to allow room for arrow */
.dialogueContentGrace {
   padding-bottom: 5rem;
}

/* TIMELINE ELEMENT (FIRST PART IS IN FILM.JS) */
.dialogueTimeline {
    position: absolute;
    top: 0px;
    left: calc(0.5*var(--arrow-width) - 0.5*var(--timeline-width));
    height: 100%;
    width: var(--timeline-width);
    background-color: black;
}

/* Circle element before timeline */
.dialogueTimeline::after {
    position: absolute;
    bottom: 0;
    left: calc(-0.5*var(--timeline-node) + 0.5*var(--timeline-width));
    content: "";
    width:  var(--timeline-node);
    height: var(--timeline-node);
    background-color: black;
    border-radius: 50%;
}

/* ----- COMPONENTS ----- */

/* QUESTION AND EXERCISE COMPONENT */
.questionContainer {   
    position: relative;
    margin-top: var(--dialogue-row-gap);
    padding-block: 0.5rem;
}

.exerciseContainer {
    position: relative;
    margin-top: calc(3.5*var(--dialogue-row-gap));
}

.exerciseTitle {
    position: absolute;
    width: fit-content;
    top: -2em;
    margin-left: calc(100% - var(--film-width));
    margin-bottom: 0.3em;
}

.exerciseLine, .questionLine {
    position: absolute;
    top: calc(50% - 0.5*var(--timeline-width));
    left: calc(0.5 * var(--arrow-width));
    /* Width needed to reach edge of film from timeline */
    width: calc(100% - var(--film-width) - 0.5*var(--arrow-width));
    height: var(--timeline-width);
    background-color: black;
}

.exerciseText, .questionText {
    width: fit-content;
    /* Width needed to reach edge of film from main left margin */
    margin-left: calc(100% - var(--film-width));
    border: var(--timeline-width) solid black;
    padding-inline: 0.8em;
    padding-block: 0.3em;
}

.exerciseText {
    max-width: 580px;
}

.questionText {
    max-width: 600px;
    border: none;
    border-left: var(--timeline-width) solid black;
}

.exerciseText p {
    margin-bottom: 1em;
}

.exerciseText p:last-child {
    margin-bottom: 0;
}

/* CHECKBOX */
.checkbox {
    position: absolute;
    top: calc(50% - 1.25rem);
    left: calc(0.5*var(--arrow-width) - 0.5*var(--checkbox-width));
    width: var(--checkbox-width);
    height: var(--checkbox-width);
    display: flex;
    justify-content: center;
    align-items: center;
    border: var(--timeline-width) solid black;
    border-radius: 50%;
    cursor: pointer;
    /* Scale transition */
    transition: transform 150ms linear;
    -webkit-transition: transform 150ms linear;
    -moz-transition: transform 150ms linear;
    -o-transition: transform 150ms linear;
    -ms-transition: transform 150ms linear;
}

/* Rotate dash in checkbox on hover
Use media query for hover to disable for mobile */
@media (hover: hover) {
    .checkbox:hover {
        transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
    }
}

/* Tab focus styles */
.checkbox:focus-visible {
    background-color: #71c7e9;
    outline: none;
}

.checked {
    background-color: black;
    color: white;
}

.checked:hover {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
}

.notChecked {
    background-color: #fff;
    color: black;
}

.checkboxPointer {
    position: absolute;
    width: 3.5rem;
    top: 2.4rem;
    left: 0.6rem;
}

/* PARAGRAPH AND MESSAGE CONTAINER */
.paragraphContainer {
    margin-top: calc(var(--dialogue-row-gap));
}

.messageContainer {
    margin-block: calc(var(--dialogue-row-gap));
}

.paragraphText {
    max-width: 600px;
    margin-left: calc(100% - var(--film-width));
}

/* Background colour set inline (see message.js) */
.messageText {
    position: relative;
    width: fit-content;
    margin-left: calc(100% - var(--film-width));
    padding-inline: 0.8em;
    background-image: linear-gradient(135deg, #5b5b5b 4.55%, #000000 4.55%, #000000 50%, #5b5b5b 50%, #5b5b5b 54.55%, #000000 54.55%, #000000 100%);
    background-size: 31.11px 31.11px;
    color: white;
    border-radius: 0.5rem;
    box-shadow: 0px 0 5px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 0 5px rgba(0, 0, 0, 0.2);
}

/* Speech bubble diagonal */
/* Border right colour inherited from inline styling of .messageText (see message.js) */
.messageText::before {
    position: absolute;
    content: "";
    top: calc(50% - 0.3em);
    left: -0.40em;
    border: 0.4em solid transparent;
	border-left: none;
	border-right-color: #191919;
    
}

@media screen and (max-width: 767px){
    .dialogueContent {
        margin-right: 0;
        padding-top: 3rem;
        padding-bottom: 5rem;
    }
    .exerciseTitle {
        /* Overide App.css style */
        font-size: 2.18em;
    }
    .exerciseText, .exerciseTitle, .questionText, .paragraphText, .messageText {
        margin-left: 20%;
    }
    .exerciseLine, .questionLine {
        width: calc(20% - 0.5*var(--arrow-width)); 
    }
}

/* Move elements 1rem left to make more room for dialogue */
@media screen and (max-width: 600px){
    .dialogueTimeline {
        left: calc(0.5 * var(--arrow-width) - 1rem);
    }
    .checkbox {
        left: calc(0.5*var(--arrow-width) - 1rem - 0.5*var(--checkbox-width) + 0.5*var(--timeline-width));
    }
    .questionLine, .exerciseLine {
        left: calc(0.5 * var(--arrow-width) - 1rem);
        /* Make line contecting to timeline 1em longer to compensate  */
        width: calc(20% - 0.5*var(--arrow-width) + 1rem); 
    }
    .checkboxPointer {
        left: -0.8rem;
    }
}

@media screen and (max-width: 450px) {
    /* Restyle exercise box for mobile - full width (less margins) with text centered */
    /* Remove line connecting to timeline at the same time */
    .exerciseTitle {
        width: 100%;
        margin-left: 0;
        /* Overide App.css style */
        font-size: 2.18em;
        text-align: right;
    }
    .exerciseText {
        background-color: white;
        width: 100%;
        padding-block: 0.6em;
        padding-inline: 1em;
        margin-left: 0;
    }
    .exerciseCheckbox {
        display: none;
    }
    .exerciseLine {
        display: none;
    }
}